import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Config } from '../../components';
import { Country, CountryLanguage, UpdateCountryRequest } from '../../contracts';
import { ErrorHandler } from '../../util';
import { updateCountryMutation } from './Mutations';
import { getAllCountriesQuery, getCountryByCountryCodeQuery, getLanguagePreferLevelsByCountryIdQuery } from './Queries';


export class CountryApolloClient {

  private apolloClient: ApolloClient<NormalizedCacheObject>;

  constructor(identityToken: string, config: Config) {
    const httpLink = createHttpLink({
      uri: config.webAPIConfig.graphQlHttpApiUrl,
    });

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: identityToken ? `Bearer ${identityToken}` : '',
      },
    }));

    this.apolloClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }

  public async getCountryByCountryCode(coutryCode: string): Promise<Country | null> {
    try {
      const queryResult = await this.apolloClient.query({
        query: getCountryByCountryCodeQuery(coutryCode),
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      return queryResult.data.countryByCountryCode;
    } catch (error) {
      ErrorHandler.handleError(error);
      return null;
    }
  }

  public async getAllCountries(): Promise<Array<Country>> {
    try {
      const queryResult = await this.apolloClient.query({
        query: getAllCountriesQuery,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      return queryResult.data.countries;
    } catch (error) {
      ErrorHandler.handleError(error);
      return [];
    }
  }

  public async getLanguagePreferLevelsByCountryId(countryId: string): Promise<Array<CountryLanguage>> {
    try {
      const queryResult = await this.apolloClient.query({
        query: getLanguagePreferLevelsByCountryIdQuery(countryId),
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      return queryResult.data.languagePreferLevelsByCountryId;
    } catch (error) {
      ErrorHandler.handleError(error);
      return [];
    }
  }

  public async updateCountry(updateCountryRequest: UpdateCountryRequest): Promise<Country | null> {
    try {
      const mutation = updateCountryMutation(updateCountryRequest);
      const mutationResult = await this.apolloClient.mutate({
        mutation: mutation,
        errorPolicy: 'all',
      });

      const countryResult: Country = {
        id: mutationResult.data.updateCountry.country.id,
        name: mutationResult.data.updateCountry.country.name,
        countryCode: mutationResult.data.updateCountry.country.countryCode,
        logisticType: mutationResult.data.updateCountry.country.logisticType,
        localPartnerEmail: mutationResult.data.updateCountry.country.localPartnerEmail,
        tesName: mutationResult.data.updateCountry.country.tesName,
        tesAddress: mutationResult.data.updateCountry.country.tesAddress,
        tesCity: mutationResult.data.updateCountry.country.tesCity,
        tesPostalCode: mutationResult.data.updateCountry.country.tesPostalCode,
        tesShipperNumber: mutationResult.data.updateCountry.country.tesShipperNumber,
        tesPhoneNumber: mutationResult.data.updateCountry.country.tesPhoneNumber,
      };

      return countryResult;

    } catch (error) {
      ErrorHandler.handleError(error);
      return null;
    }
  }

}
