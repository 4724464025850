/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ToastContainer } from 'react-toastify';

import { DocumentApolloClient } from '../../../api';
import { Config } from '../../../components/DialogRenderer';
import { parseLanguageCountryCode } from '../../../util';
import i18n from '../../../util/i18n';
import './SuccessPageCustomForm.css';
import { HeaderComponent } from '../../../components/HeaderComponent';
import { LogisticTypeName } from '../../../contracts';
import { CustomFormProps } from '../../../components/DialogRenderer/CustomFormsRenderer/contracts';
import { isRightToLeft } from '../../../util/isRightToLeft';

export class SuccessPageCustomForm extends React.Component<CustomFormProps> {

  public state: { file: Blob } = { file: new Blob() };
  private documentApolloClient: DocumentApolloClient;

  private doorstepCollectionPickupDate: string;

  private doorstepCollectionReadyTime: string;

  private doorstepCollectionCloseTime: string;

  private locale: string;

  constructor(props: CustomFormProps) {
    super(props);

    this.documentApolloClient = new DocumentApolloClient(props.identity.token, props.config as Config);

    const languageCode = this.props.userTask.tokens[0].payload.language.languageCode;
    const countryCode = this.props.userTask.tokens[0].payload.country.countryCode;
    this.locale = parseLanguageCountryCode(languageCode, countryCode);

    this.doorstepCollectionPickupDate = this.castUpsDateToLocaleDateString(this.props.userTask.tokens[0].payload.pickupDate);
    this.doorstepCollectionReadyTime = this.castUpsTimeToLocaleTimeString(this.props.userTask.tokens[0].payload.readyTime);
    this.doorstepCollectionCloseTime = this.castUpsTimeToLocaleTimeString(this.props.userTask.tokens[0].payload.closeTime);

    i18n.changeLanguage(parseLanguageCountryCode(languageCode, countryCode)).catch(() => {
      console.log('Language couldn\'t be translated');
    });
  }

  public async componentDidMount(): Promise<void> {
    const file: Blob = await this._getPdf();
    this.setState({ file: file });
  }

  public render(): JSX.Element {
    return (
      <div dir={isRightToLeft(this.props.userTask.tokens[0].payload.language.languageCode) ? 'ltr' : 'ltr'}>
        <HeaderComponent config={this.props.config} />
        <div className='flex flex-col mx-auto w-[600px]'>
          <h2 className='text-center font-bold my-4'>
            <>{i18n.t('SuccessPage_Headline')}</>
          </h2>
          <p><>{i18n.t('SuccessPage_Subtext1')}</></p>

          <p className='mt-4 font-bold'><>{i18n.t('SuccessPage_ReferenceNumber_Headline1')}</></p>
          <p className='mt-2'><>{i18n.t('SuccessPage_ReferenceNumber_Subtext1')}<br />{this.props.userTask.correlationId}</></p>
          <p className='mt-2'><>{i18n.t('SuccessPage_ReferenceNumber_Subtext2')}</></p>

          <p className='mt-4 font-bold'><>{i18n.t('SuccessPage_Packing_Headline1')}</></p>
          <p className='mt-2'><>{i18n.t('SuccessPage_Packing_Subtext1')}</></p>
          <p className='mt-2'><>{i18n.t('SuccessPage_Packing_Subtext2')}</></p>

          {this.isAutomaticDoorstepCollection() &&
            <div>
              <p className='mt-4 font-bold'><>{i18n.t('SuccessPage_Label_Doorstep_Headline1')}</></p>
              <p className='mt-2' style={{ whiteSpace: 'pre-line' }}><>{i18n.t('SuccessPage_Label_DoorstepAutomatic_Subtext1', { linebreak: '\n', pickupDate: this.doorstepCollectionPickupDate, readyTime: this.doorstepCollectionReadyTime, closeTime: this.doorstepCollectionCloseTime })}</></p>
            </div>
          }
          {this.isAutomaticLabel() &&
            <div>
              <p className='mt-4 font-bold'><>{i18n.t('SuccessPage_Label_Headline1')}</></p>
              <p className='mt-2'><>{i18n.t('SuccessPage_Label_Subtext1')}</></p>
            </div>
          }

          <div className='Body__Download-PDF h-12 flex items-center gap-4 mt-4 mb-8'>
            <a id='downloadButton'
              style={this.state.file.size === 0 ? { backgroundColor: '#A0AEC0', pointerEvents: 'none' } : {}}
              onClick={this._downloadPdf.bind(this)}
              download='Instruction.pdf'
              className='bg-blue-500 disabled:bg-gray-700 enabled:hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 enabled:border-blue-700 enabled:hover:border-blue-500 rounded-xl hover:cursor-pointer'
            >
              <>{i18n.t('Download Instruction')}</>
            </a>
          </div>
        </div>
        <ToastContainer position='bottom-right' />
      </div>
    );
  }

  private async _getPdf(): Promise<Blob> {
    const correlationId = this.props.userTask.correlationId;
    const documents = await this.documentApolloClient.getAllDocumentsByCorrelationId(correlationId);

    const file = documents[0].data;
    const bytes = new Uint8Array(file);
    const blob = new Blob([bytes], { type: 'application/pdf' });

    return blob;
  }


  private _downloadPdf(): void {
    const filename = `${this.props.userTask.correlationId}.pdf`;

    const url = window.URL.createObjectURL(this.state.file);
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', filename);
    tempLink.click();
  }

  private isAutomaticLabel(): boolean {
    const logisticTypeName = this.props.userTask.tokens[0].payload.country.logisticType.name;

    return logisticTypeName === LogisticTypeName.automatic;
  }

  private isAutomaticDoorstepCollection(): boolean {
    const logisticTypeName = this.props.userTask.tokens[0].payload.country.logisticType.name;

    return logisticTypeName === LogisticTypeName.automatic_doorstep;
  }

  private castUpsDateToLocaleDateString(dateString: string): string {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    const date = new Date(`${year}-${month}-${day}`);

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    let locale: Intl.LocalesArgument = this.locale;
    locale = locale.replace('_', '-');

    const localeDateString = date.toLocaleDateString(locale, options);

    return localeDateString;
  }

  private castUpsTimeToLocaleTimeString(timeString: string): string {
    const hour = timeString.substring(0, 2);
    const minute = timeString.substring(2, 4);

    const time = new Date();
    time.setHours(parseInt(hour, 10));
    time.setMinutes(parseInt(minute, 10));

    const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
    let locale: Intl.LocalesArgument = this.locale;
    locale = locale.replace('_', '-');

    const localeTimeString = time.toLocaleTimeString(locale, options);

    return localeTimeString;
  }

}
