import { DocumentNode, gql } from '@apollo/client';
import { UpdateCountryRequest } from '../../../contracts';

export function updateCountryMutation(updateCountryRequest: UpdateCountryRequest): DocumentNode {
  return gql`
    mutation {
      updateCountry(input: {
        id: "${updateCountryRequest.id}"
        name: "${updateCountryRequest.name}"
        countryCode: "${updateCountryRequest.countryCode.toUpperCase()}"
        logisticTypeId: "${updateCountryRequest.logisticTypeId}"
        localPartnerEmail: "${updateCountryRequest.localPartnerEmail}"
        tesName: "${updateCountryRequest.tesName}"
        tesAddress: "${updateCountryRequest.tesAddress}"
        tesCity: "${updateCountryRequest.tesCity}"
        tesPostalCode: "${updateCountryRequest.tesPostalCode}"
        tesShipperNumber: "${updateCountryRequest.tesShipperNumber}"
        tesPhoneNumber: "${updateCountryRequest.tesPhoneNumber}"
      })
      {
        country {
          id,
          name,
          countryCode,
          logisticTypeId,
          localPartnerEmail,
          tesName,
          tesAddress,
          tesCity,
          tesPostalCode,
          tesShipperNumber,
          tesPhoneNumber,
        }
      }
    }
  `;
}
