import { gql } from '@apollo/client';

export const getAllCountriesQuery = gql`
{
  countries {
    id,
    name,
    countryCode,
    logisticType {
      id,
      name,
    },
    localPartnerEmail,
    tesName,
    tesAddress,
    tesCity,
    tesPostalCode,
    tesShipperNumber,
    tesPhoneNumber,
  }
}
`;
