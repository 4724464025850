import { ChangeEvent, Component } from 'react';

import { Country, Language } from '../../../../contracts';
import { parseCountryFromUrl, parseLanguageCountryCode } from '../../../../util';
import i18n from '../../../../util/i18n';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import { CountrySelectorProps, CountrySelectorState } from './contracts';

export class CountrySelector extends Component<CountrySelectorProps, CountrySelectorState> {

  private defaultCountryCode = '';

  constructor(props: CountrySelectorProps) {
    super(props);

    this.state = {
      currentCountry: {
        id: '',
        countryCode: '',
        name: '',
        logisticType: {
          id: '',
          name: '',
        },
        localPartnerEmail: '',
        tesName: '',
        tesAddress: '',
        tesCity: '',
        tesPostalCode: '',
        tesShipperNumber: '',
        tesPhoneNumber: '',
      },
      defaultLanguage: { id: '', languageCode: '', name: '' },
      supportedLanguages: [{ id: '', languageCode: '', name: '' }],
    };

    this._getCountryFromUrl();

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this._getLanguageChange = this._getLanguageChange.bind(this);
    this._finishedLoading = this._finishedLoading.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    const defaultCountry = await this.props.countryApolloClient.getCountryByCountryCode(this.defaultCountryCode);

    if (defaultCountry) {
      await this._setLanguagesForCountry(defaultCountry);


      this.setState({
        currentCountry: defaultCountry,
      });
    }

  }

  render(): JSX.Element {
    return (
      <div>
        <LanguageSelector
          currentCountry={this.state.currentCountry}
          defaultLanguage={this.state.defaultLanguage}
          supportedLanguages={this.state.supportedLanguages}
          getLanguageChange={this._getLanguageChange}
          finishedLoading={this._finishedLoading}
        />
      </div>
    );
  }

  private async _setLanguagesForCountry(country: Country): Promise<void> {
    const languages = await this.props.languageApolloClient.getLanguagesByCountry(country.id);
    const countryLanguages = await this.props.countryApolloClient.getLanguagePreferLevelsByCountryId(country.id);

    const defaultLanguageId = countryLanguages.find(countryLanguage => countryLanguage.preferLevel === 1)?.languageId;
    let defaultLanguage = languages.find(language => language.id === defaultLanguageId);

    if (!defaultLanguage) {
      defaultLanguage = languages.find((language) => language.languageCode === this.props.config.fallBackLanguageCode);

      if (!defaultLanguage)
        return;
    }
    this.props.getCountryChange(country);
    this.props.getLanguageChange(defaultLanguage);

    this.setState({
      defaultLanguage: defaultLanguage,
      supportedLanguages: languages,
    });

    await i18n.changeLanguage(parseLanguageCountryCode(defaultLanguage.languageCode, country.countryCode));
  }

  private async handleCountryChange(e: ChangeEvent): Promise<void> {
    const value: Country = JSON.parse((e.target as HTMLInputElement).value);
    await this._setLanguagesForCountry(value);

    this.setState({
      currentCountry: value,
    });
  }

  private _finishedLoading(finishedLoading: boolean): void {
    this.props.finishedLoading(finishedLoading);
  }


  private _getLanguageChange(language: Language): void {
    this.props.getLanguageChange(language);
  }

  private _getCountryFromUrl(): void {
    if (this.props.portalLocation !== undefined) {
      this.defaultCountryCode = parseCountryFromUrl(this.props.portalLocation?.search);
    }

    if (this.defaultCountryCode === '') {
      this.defaultCountryCode = this.props.config.fallBackCountryCode;
    }

  }

}
