import { DocumentNode, gql } from '@apollo/client';

export const getCountryByCountryCodeQuery = (countryCode: string): DocumentNode => gql`
  query {
    countryByCountryCode(countryCode: "${countryCode}") {
      id,
      name,
      countryCode,
      logisticType {
        id,
        name,
      },
      localPartnerEmail,
      tesName,
      tesAddress,
      tesCity,
      tesPostalCode,
      tesShipperNumber,
      tesPhoneNumber,
    }
  }
`;
